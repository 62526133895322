.scrollButton {
  background-color: #e0e1e1;
  border-radius: 50px;
  padding: 0.8rem;
  position: fixed;
  bottom: 3%;
  right: 2%;
  cursor: pointer;
  transition: all 0.5s;
}
.scrollIcon {
  font-size: 1.2rem;
}

.scrollButton:hover {
  transform: scale(1.2);
}
