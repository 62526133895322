.dummyHeaderContainer {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
}

.dummyHeader {
  position: relative;
  height: 250px;
  background-color: #7b7e8b;
}

.dummyHeader p {
  position: absolute;
  top: 40%;
  text-align: center;
  color: #fff;
  width: 100%;
}

.quizCard {
  position: absolute;
  width: 90%;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 10px;
}

.quizCardHeader {
  height: 70px;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quizCardHeader .timerContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.quizCardHeader .timerContainer .timer {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-size: 1.5rem;
}

.exitButton {
  padding: 0.6rem 1.5rem;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: rgba(254, 97, 95, 0.15);
  color: #fe615f;
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 10px;
}

.quizCardContent {
  position: relative;
  display: grid;
  grid-template-columns: 70% 30%;
}

.quizCardContent .questionContainer {
  border-right: 1px solid #ccc;
  padding: 2rem 1rem;
  position: relative;
  min-height: 80vh;
}

.quizCardContent .questionContainer.fullScreen {
  min-height: 60vh;
}

.questionContainer .question {
  font-size: 18px;
  letter-spacing: 0.4px;
  line-height: 2;
  text-align: justify;
}

.reviewContainer {
  padding: 2rem 1rem;
}

.reviewContainer .reviewButton {
  background-color: rgba(144, 136, 254, 0.2);
  transition: all ease 0.3s;
  color: #9088fe;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 50px;
  width: 100%;
}

.reviewContainer .reviewButton:hover {
  background-color: rgba(144, 136, 254, 0.4);
}

.reviewContainer .reviews {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  gap: 0.5rem;
  grid-auto-rows: 50px;
}

.reviewContainer .reviews .review {
  padding: 1rem;
  background-color: #f4f6fb;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  transition: all 0.5s ease;
}

.reviewContainer .reviews .review.active {
  background-color: #cde5ff !important;
  border: 1.5px solid #017cff !important;
  color: #017cff !important;
  font-weight: bold;
}

.reviewContainer .reviews .review.answered {
  background-color: #e5f8f1;
  border: 1.5px solid #e5f8f1;
  color: #01bf7e;
  font-weight: bold;
}

.reviewContainer .reviews .review.reviewMarkedAnswered {
  background-color: #fdf3ea;
  border: 1.5px solid #79e4bf;
  color: #f49475;
  font-weight: bold;
}

.reviewContainer .reviews .review.reviewMarkedNotAnswered {
  background-color: #fdf3ea;
  border: 1.5px solid #fdf3ea;
  color: #f49475;
  font-weight: bold;
}

.reviewContainer .reviews .review:hover {
  background-color: #e5e9f5;
}

.optionsContainer {
  background-color: #f4f6fb;
  margin-top: 1rem;
  width: 90%;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.optionsContainer .option {
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  margin-top: 0.9rem;
}

.optionsContainer .option.selected {
  background-color: #e5f8f1;
}

.quizCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 5%;
  width: 96%;
}

.quizCardFooter button {
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: #2ab36e;
  color: #fff;
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 10px;
}

.quizCardFooter button.disabled {
  background-color: #a1d9b6;
  cursor: not-allowed;
}

.fullScreenMode {
  position: absolute;
  right: 0%;
  bottom: -10%;
}

.questionContainer .question.blank input {
  border: none;
  min-width: 200px;
  border-bottom: 1px solid grey;
  outline: none;
  margin: 0rem 1rem;
  font-size: 18px;
  transition: all ease 0.4s;
  text-align: center;
}

.questionContainer .question.blank input:focus {
  border-bottom: 1px solid var(--primary);
}

.reviewTable td {
  font-size: 0.8rem;
  font-weight: lighter;
}

.viewButton {
  background-color: #fff;
  color: blue;
}

@media (max-width: 768px) {
  .dummyHeaderContainer {
    display: none;
  }
  .quizCard {
    width: 110%;
    position: relative;
    background-color: #fff;
  }
  .quizCardHeader {
    flex-direction: column;
    height: auto;
    gap: 1rem;
  }

  .fullScreenMode {
    display: none;
  }

  .quizCardHeader .timerContainer .timer {
    font-size: 1.2rem;
  }

  .quizCardContent {
    position: relative;
    display: block;
  }

  .optionsContainer {
    width: 100%;
  }

  .quizCardContent .questionContainer {
    min-height: 70vh;
  }

  .quizCardFooter {
    flex-direction: column;
    position: relative;
    margin-top: 5rem;
    gap: 1rem;
  }
}
