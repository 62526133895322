.bannerImage {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.playButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem !important;
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 1.5rem !important;
}

.reOrderButton,
.editButton,
.deleteButton {
  background-color: skyblue;
  color: white;
  padding: 0.3rem 0.6rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease;
}

.deleteButton {
  background-color: tomato;
}

.reOrderButton:hover,
.editButton:hover,
.deleteButton:hover {
  transform: scale(1.1);
}

.playButton {
  color: skyblue !important;
  font-weight: bolder;
  transition: all ease 0.5s;
}

.playButton:hover {
  color: tomato !important;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust the z-index based on your requirements */
}

.modalContent {
  position: relative;
  outline: none;
  overflow: hidden;
  width: 80%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  z-index: 1001; /* Should be higher than the overlay's z-index */
}

.duration {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.2rem;
  color: #afb1b2;
  font-size: 14px;
}

.sectionActionContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.editButton {
  background-color: orange;
}

.banner {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.courses {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.course {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}
.courseTitle {
  font-size: 1.2rem;
  font-weight: bolder;
}

.courseHeadline {
  color: grey;
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: 0.8rem;
}

.durationContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.6rem;
}

.totalDuration,
.totalLectures {
  color: #2d3032;
  font-size: 0.9rem;
  font-weight: lighter;
}

.checkboxActionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
